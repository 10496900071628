<script>
  import PieceSelection from "./PieceSelection.svelte"
  import Content from "./Content.svelte"
  import Impressum from "./Impressum.svelte"
  import Videos from "./Videos.svelte"
  import CookieConsent from "./CookieConsent.svelte"

  import { filteredPieces } from "./data"

  import Logo from "./img/logo.svg"
  import Decoration from "./img/decoration.svg"
  import { onMount } from "svelte"

  // REACTIVE VARIABLES

  let pieceIndex = 0
  let partIndex = 0
  let cookieConsent = false

  $: {
    partIndex = pieceIndex ? 0 : 0
  }

  onMount(() => {
    cookieConsent = getCookieValue("cookie_consent")
  })

  const handleCookieConsent = () => {
    setCookie("cookie_consent", true)
    cookieConsent = getCookieValue("cookie_consent")
  }

  const getCookieValue = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""

  function setCookie(name, value, days = 30) {
    var expires = ""
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }
</script>

<main class="main ">
  {#if !cookieConsent}
    <CookieConsent on:consent={handleCookieConsent} />
  {/if}

  <div class="decoration">
    {@html Decoration}
  </div>
  <div class="content stack">
    <div class="logo">
      {@html Logo}
    </div>
    <PieceSelection bind:pieceIndex bind:partIndex pieces={filteredPieces} />

    <Videos {cookieConsent} {partIndex} {pieceIndex} />

    <Content />
    <Impressum />
  </div>
</main>

<style global lang="scss">
  @import "./main.scss";

  .decoration {
    position: absolute;
    inset: 0;
    overflow: visible;
    user-select: none;
    pointer-events: none;
    > * {
      position: absolute;
      width: 157%;
      right: 0;
      transform: translate(0, -50%);
    }
  }

  .content {
    position: relative;
  }

  .main {
    padding: 2.4rem var(--side-spacing);
    position: relative;
    margin: 0 auto;
    --space: var(--size-600);
    max-width: 120rem;
    overflow: hidden;
  }

  .logo {
    max-width: 70%;
    margin: 0 auto;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 600px) {
      max-width: 85%;
    }
  }
</style>
