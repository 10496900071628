<script>
  export let pieceIndex
  export let partIndex
  export let pieces
</script>

<div class="piece-selection">
  <div class="cluster">
    <div class="piece-selection__pieces">
      {#each pieces as piece, i}
        <div
          on:click={() => (pieceIndex = i)}
          class="piece-selection__piece {i == pieceIndex ? 'is-active' : ''}"
        >
          {piece.artist}
        </div>
      {/each}
    </div>

    <div class="piece-selection__current-piece">
      {pieces[pieceIndex].name}
    </div>

    <div class="piece-selection__parts">
      {#each pieces[pieceIndex].parts as part, i}
        <div
          on:click={() => (partIndex = i)}
          class="piece-selection__part  {i == partIndex ? 'is-active' : ''}"
        >
          {part.name}
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .piece-selection {
    font-family: var(--font-headings);
    text-transform: uppercase;
    text-align: center;
    overflow: visible;

    .cluster {
      overflow: visible;
    }

    &__pieces,
    &__parts,
    &__current-piece {
      justify-content: center;
    }

    &__current-piece {
      margin-bottom: var(--space);
      font-size: var(--size-400);
      color: var(--color-interactive-light);
      display: inline-block;
      padding: 0 2rem;
    }

    &__pieces {
      font-size: var(--size-500);
      margin-bottom: var(--space);
    }

    &__parts {
      font-size: var(--size-400);
      margin-bottom: 0.8rem;
    }

    &__piece,
    &__part {
      cursor: pointer;
      transition: color 150ms ease-in-out;
      min-width: 2em;
    }

    &__piece:hover,
    &__part:hover {
      color: var(--color-interactive-light);
    }

    &__piece.is-active,
    &__current-piece,
    &__part.is-active {
      color: var(--color-interactive);
      position: relative;
      &:after {
        content: "";
        display: block;
        background-color: var(--color-interactive);
        position: absolute;
        width: 80%;
        left: 10%;
        right: 10%;
        bottom: -0.4rem;
        height: 0.4rem;
      }
    }
  }
</style>
