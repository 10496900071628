<script>
  import { createEventDispatcher } from "svelte"
  const dispatch = createEventDispatcher()
</script>

<div class="cookie-consent">
  <div class="modal">
    <p>
      Die Website verwendet Cookies, um alle Funktionen der Website
      bereitzustellen. Für weitere Informationen verweisen wir auf unsere
      Richtlinie unter folgenden Link <a href="/de/datenschutz" target="_blank"
        >Datenschutzerklärung und Cookie-Richtlinie</a
      >.
    </p>
    <button on:click={() => dispatch("consent")} class="button button--dark"
      >Zustimmen</button
    >
  </div>
</div>

<style lang="scss">
  .cookie-consent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 4;
    display: grid;
    place-content: center;
    width: 100vw;

    a {
      color: inherit;
    }

    .modal {
      margin: 4rem;
      max-width: 80rem;
      background: var(--color-interactive);
      color: var(--warmGray-900);
      padding: 2.4rem;
    }
  }
</style>
