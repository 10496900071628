<script>
  import YouTube from "./Player.svelte"
  import ListVideos from "./ListVideos.svelte"
  import Loader from "./Loader.svelte"
  import VideoControls from "./VideoControls.svelte"
  import VideoModal from "./VideoModal.svelte"

  import { PlayerState } from "./Player.svelte"
  import { filteredPieces } from "./data"

  // CONSTS FOR CONFIG
  const GLOBAL_DELAY = 100 / 1000
  const WAITING_LOADING_TIME = 3000
  const DEFAULT_FADE_TIME = 600

  // PROPS
  export let partIndex
  export let pieceIndex
  export let cookieConsent

  // REACTIVE VARIABLES
  let fullscreen
  let showVideoModal = true
  let currentVideo = 0
  let videos = filteredPieces[pieceIndex].parts[partIndex].videos
  let videoA = videos[0]
  let videoB = videos[1]
  $: {
    videos = filteredPieces[pieceIndex].parts[partIndex].videos
    videoA = videos[0]
    videoB = videos[1]
    currentVideo = 0
    currentPlayer = "A"
  }

  let playerA
  let playerB

  let currentPlayer = "A"
  $: currentPlayerObject = currentPlayer === "A" ? playerA : playerB
  let isLoading = false
  let isSyncDone = true

  const youtubeOptions = {
    playerVars: {
      enablejsapi: 1,
      fs: 0,
      controls: 0,
      disablekb: true,
      // hl: "de",
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      playsinline: 1,
      color: "rgb(251, 207, 232)",
    },
  }

  const youtubeOptionsB = JSON.parse(JSON.stringify(youtubeOptions))

  // EVENT HANDLERS
  let onPlayerACreated = async (event) => {
    playerA = event.detail
    // resetSphericalProps(playerA)
  }

  let onPlayerBCreated = async (event) => {
    playerB = event.detail
    // resetSphericalProps(playerB)
  }

  const onPlayerAStatusChange = async (event) => {
    onPlayerChange(event, playerB, playerA)
  }

  const onPlayerBStatusChange = async (event) => {
    onPlayerChange(event, playerA, playerB)
  }

  const onPlayerChange = async (event, mainPlayer, followerPlayer) => {
    if ((event.detail.data == PlayerState.PLAYING || event.detail.data == PlayerState.CUED) && !isSyncDone) {
      // A first one to preload the video
      setTimeout(async () => {
        await syncVideo(mainPlayer, followerPlayer)
      }, WAITING_LOADING_TIME / 8)

      // Another one, later whern we knows the video is loaded
      setTimeout(async () => {
        await syncVideo(mainPlayer, followerPlayer)
        fadeOut(mainPlayer)
        fadeIn(followerPlayer)
        isLoading = false
      }, WAITING_LOADING_TIME)
      isSyncDone = true
    }
  }

  const handleModalAccept = () => {
    currentPlayerObject.playVideo()
    showVideoModal = false
  }

  // FUNCTIONS

  // const resetSphericalProps = (player) => {
  //   console.log({ player })
  //   player.setSphericalProperties({ yaw: 0, pitch: 0, roll: 0, fov: 10 })
  // }

  let loadVideo = async (videoId) => {
    console.log("loading video", videoId);
    currentVideo = videoId
    isLoading = true
    isSyncDone = false
    let newPlayer, oldPlayer
    if (currentPlayer == "A") {
      newPlayer = playerB
      oldPlayer = playerA
      videoB = videos[videoId]
      currentPlayer = "B"
    } else {
      newPlayer = playerA
      oldPlayer = playerB
      videoA = videos[videoId]
      currentPlayer = "A"
    }

    await syncVideo(oldPlayer, newPlayer)
  }

  const syncVideo = async (main, follower) => {
    let startingTime = performance.now()

    const oldPlayerTime = await main.getCurrentTime()
    let endingTime = performance.now()
    const timeToExecute = (endingTime - startingTime) / 1000
    await follower.seekTo(oldPlayerTime + timeToExecute + GLOBAL_DELAY, true)
    await follower.setVolume(0)
  }

  const fade = (player, time, fadeOut = false) => {
    const divider = 40
    const timeFraction = time / divider
    const volumeFraction = 100 / divider

    let timeRun = 0
    let interval = setInterval(() => {
      timeRun += 1
      let newVolume
      if (fadeOut) {
        newVolume = 100 - volumeFraction * timeRun
      } else {
        newVolume = volumeFraction * timeRun
      }
      player.setVolume(newVolume)

      if (timeRun >= divider) {
        clearInterval(interval)
      }
    }, timeFraction)
  }

  const fadeIn = (player, time = DEFAULT_FADE_TIME) => {
    fade(player, time)
  }

  const fadeOut = (player, time = DEFAULT_FADE_TIME) => {
    fade(player, time, true)
  }

  const switchToVideo = (event) => {
    loadVideo(event.detail)
  }
</script>

<div
  bind:this={fullscreen}
  class="video-container {isLoading ? 'is-loading' : ''}"
>
  {#if isLoading}
    <div class="loading">
      <Loader />
    </div>
  {/if}

  {#if !showVideoModal}
    <ListVideos bind:videos {currentVideo} on:select={switchToVideo} />

    <VideoControls bind:player={currentPlayerObject} bind:fullscreen />
  {/if}

  <div class="show-video">
    {#if showVideoModal}
      <VideoModal on:accept={handleModalAccept} />
    {/if}
    <div class="frame">
      {#if cookieConsent == "true"}
        <div
          class="video videoA {(currentPlayer == 'A' && !isLoading) ||
          (currentPlayer == 'B' && isLoading)
            ? 'is-active'
            : ''}"
        >
          <YouTube
            on:error={console.error}
            on:stateChange={onPlayerAStatusChange}
            on:created={onPlayerACreated}
            videoId={videoA.src}
            options={youtubeOptions}
          />
        </div>

        <div
          class="video videoB {(currentPlayer == 'B' && !isLoading) ||
          (currentPlayer == 'A' && isLoading)
            ? 'is-active'
            : ''}"
        >
          <YouTube
            on:error={console.error}
            on:stateChange={onPlayerBStatusChange}
            on:created={onPlayerBCreated}
            videoId={videoB.src}
            options={youtubeOptionsB}
          />
        </div>
      {/if}
    </div>
  </div>
</div>

<style global lang="scss">
  .video-container {
    position: relative;
    background-color: #000;
    display: flex;
    flex-direction: column;

    &.fullscreen {
      z-index: 5;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;

      .show-video {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &:-webkit-full-screen {
      width: 100%;
      height: 100%;
    }

    &.is-loading {
      pointer-events: none;
    }

    @media screen and (max-width: 600px) {
      margin-left: calc(var(--side-spacing) * -1);
      margin-right: calc(var(--side-spacing) * -1);
    }
  }

  .frame {
    --w: 13;
    @media screen and (min-width: 600px) {
      --w: 16;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-content: center;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.16) 100%
    );
    --loading-color: var(--warmGray-200);

    > * {
      margin-bottom: 8rem;
    }
  }

  .show-video {
    position: relative;
    flex-grow: 1;

    .video {
      visibility: hidden;

      iframe {
        width: 100%;
        height: 100%;
      }

      &.is-active {
        visibility: visible;
      }
    }

    .videoB {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
</style>
