<script>
  import IconPlay from "./img/icon-play.svg"
  import IconPause from "./img/icon-pause.svg"
  import IconFullscreen from "./img/icon-fullscreen.svg"

  import { onMount } from "svelte"

  // PROPS
  export let player
  export let fullscreen

  // REACTIVE VARIABLES
  let playerState = -1
  let playerTime = "00:00"
  let playerTimeElapsed = "00:00"

  let playerPercent = 0

  // FUNCTIONS
  const handleControls = (type) => {
    if (type == "PLAY") {
      player.playVideo()
    } else if (type == "PAUSE") {
      player.pauseVideo()
    } else if (type == "FULLSCREEN") {
      handleFullscreen()
    }
  }

  let handleFullscreen = () => {
    if (
      document.fullscreenElement ||
      fullscreen.classList.contains("fullscreen")
    ) {
      fullscreen.classList.remove("fullscreen")
      if (document.exitFullscreen) document.exitFullscreen()
    } else {
      if (fullscreen.requestFullscreen) {
        fullscreen.requestFullscreen()
      } else {
        // Iphone IOS there:/
        fullscreen.classList.add("fullscreen")
        console.log("Using iphone, quirky fullscreen")
      }
    }
  }

  // Fix when player changes
  $: {
    player.removeEventListener("onStateChange", handleStateChange)
    player.addEventListener("onStateChange", handleStateChange)
  }

  onMount(() => {
    player.addEventListener("onStateChange", handleStateChange)
    setInterval(getPlayerTime, 200)
  })

  const handleStateChange = (type) => {
    console.log({ type })

    if (type.data === 1) getPlayerTime()

    playerState = type.data
  }

  const getPlayerTime = async () => {
    playerTime = secondsToHuman(Math.round(await player.getDuration()))
    playerTimeElapsed = secondsToHuman(
      Math.round(await player.getCurrentTime())
    )

    const duration = await player.getDuration()
    const currentTime = await player.getCurrentTime()
    if(duration === 0 || currentTime == 0){
      playerPercent = 0
    }else{
      playerPercent = (currentTime / duration) * 100
    }
  }

  const handleProgressChange = async (event) => {
    const left = event.layerX
    const width = event.target.offsetWidth
    const progressPercent = left/width 
    const totalTime = await player.getDuration()
    const newTime = totalTime * progressPercent
    player.seekTo(newTime)
  }

  const secondsToHuman = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }
</script>

<div class="controls">
  <div class="inner">
    {#if playerState === 1}
      <button on:click={() => handleControls("PAUSE")}>
        {@html IconPause}
      </button>
    {:else}
      <button on:click={() => handleControls("PLAY")}>
        {@html IconPlay}
      </button>
    {/if}
    <div class="progression">
      <span>
        {playerTimeElapsed}
      </span>
    </div>
    <div class="progress-bar" style="--progress: {playerPercent}%" on:click={handleProgressChange}>
      <div class="progress-bar__current">&nbsp;</div>
    </div>
    <div class="progression">
      {playerTime}
    </div>
    <button on:click={() => handleControls("FULLSCREEN")}>
      {@html IconFullscreen}
    </button>
  </div>
</div>

<style lang="scss">
  :global(.controls svg) {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
  }
  .controls {
    position: absolute;
    bottom: 2.4rem;
    z-index: 2;
    left: 0;
    right: 0;
    text-align: center;

    .inner {
      border-radius: 0.4rem;
      background-color: var(--warmGray-900);
      padding: 1.6rem 2.4rem;
      line-height: 1;
      display: inline-flex;
      > * {
        margin-right: 1.2rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .progression {
    width: 7rem;
    overflow: visible;
    text-align: center;
    line-height: 2.4rem;
    span {
      color: var(--warmGray-400);
    }
  }

  .progress-bar{
    --bar-height: .4rem;
    --current-height: 1.2rem;
    width: 20vw;
    position: relative;
    background: var(--warmGray-600);
    height: var(--bar-height);
    align-self: center;
    user-select: none;

    &__current{
      position: absolute;
      left: var(--progress);
      display: block;
      width: var(--current-height);
      height:  var(--current-height);
      border-radius: 50%;
      background: var(--color-interactive);
      top: calc(-1*((var(--current-height)/2) - (var(--bar-height)/2)));
      transform: translateX(-50%);
    }
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    outline: none;

    &:hover {
      opacity: 0.75;
    }
  }
</style>
