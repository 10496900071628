<script>
  import IconPlay from "./img/icon-play.svg"

  import { createEventDispatcher } from "svelte"
  const dispatch = createEventDispatcher()

  const handleClick = () => {
    dispatch("accept")
  }
</script>

<div class="video-modal">
  <div class="inner">
    <div class="inner">
      <p>
        Einmal die Perspektive ändern und mitten in einem Orchester sitzen? Das ist hier möglich! Tauschen Sie Ihren Platz und erleben Sie die Instrumente hautnah. Im interaktiven Konzert können Sie entscheiden, welchen Platz Sie einnehmen. Dabei eröffnen sich völlig ungewohnte Hör- und Seherlebnisse.
      </p>
      <p>
        Schlüpfen Sie in der 360 Grad Ansicht in die Rolle des Dirigenten und erleben Sie die Musik aus dessen Perspektive. Bewegen Sie dabei Ihren Blick in alle Richtungen und erleben Sie wie sich der Klang verändert. Tauchen Sie ein in die Perspektiven der Instrumente und entdecken Sie die Musik aus der Position der Querflötistin, des Hornspielers oder beobachten Sie die flinken Finger des Pianisten, die über die Tasten huschen. 
      </p>
      <p>Wagen Sie den Perspektivenwechsel!</p>
      <p>
        Für das beste Hör- und Seherlebnis empfehlen wir Ihnen das Konzert mit Kopfhörern an Ihrem Computer zu genießen. Wir sind sicher: So haben Sie ein Orchester noch nie erlebt.
      </p>
      
      <div>
        <button
          class="button button--dark"
          type="button"
          on:click={handleClick}
        >
          <span>Let's Go</span>
          {@html IconPlay}
        </button>
      </div>
    </div>
  </div>
</div>

<style lang="scss" global>
  .video-modal {
    position: absolute;
    inset: 0;
    background: var(--color-interactive);
    z-index: 2;
    text-align: center;
    font-weight: bold;
    font-size: calc(0.8 * var(--size-300));
    color: var(--warmGray-900);

    margin: var(--size-300);
    box-sizing: border-box;
    // padding-bottom: 0;

    display: grid;
    align-content: center;

    > .inner {
      overflow: auto;
    }

    .inner .inner {
      padding: var(--space);
    }

    p {
      font-size: inherit;
    }
  }
  .button {
    display: inline flex;
    align-items: center;
  }
  .button svg {
    margin-left: 0.4rem;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    vertical-align: middle;
    path {
      fill: var(--color-interactive);
    }
  }

  @media screen and (min-width: 400px) {
    .video-modal {
      margin: var(--size-500);
    }
  }

  @media screen and (min-width: 800px) {
    .video-modal {
      margin: var(--size-700);
      // font-size: var(--size-400);
    }
  }

  @media screen and (min-width: 1000px) {
    .video-modal {
      display: grid;
      align-content: center;
    }
  }

  @media screen and (min-width: 1600px) {
    .video-modal {
      font-size: var(--size-400);
      padding: 6rem;
      margin: var(--size-900);
    }
  }
</style>
