export const pieces = [
  {
    artist: "Maurice Ravel",
    name: "Le Tombeau de Couperin",
    parts: [
      {
        name: "I. Prélude",
        videos: [
          {
            name: "360 Grad",
            src: "qXYh1UXU-fA",
          },
          {
            name: "Oboe",
            perspective: "auf den Spieler",
            src: "VMZFATSnuGc",
          },
          {
            name: "Zweite Violinen",
            perspective: "zum Dirigenten",
            src: "1YxIhc4D35A",
          },
          {
            name: "Bratsche",
            perspective: "auf die Gruppe",
            src: "Ey-AZ6bkIzU",
          },
          {
            name: "Horn",
            perspective: "auf die Ventile",
            src: "llgdT1KXCFI",
          },
          {
            name: "Zweite Violinen",
            perspective: "auf die Gruppe + den Spieler",
            src: null,
          },
        ],
      },
      {
        name: "II. Forlane",
        videos: [
          {
            name: "360 Grad",
            src: "T0wsz5Hg3EM",
          },
          {
            name: "Erste Violinen",
            perspective: "auf die Gruppe + Dirigenten",
            src: "_A2ZLHC3TIY",
          },
          { name: "Harfe", perspective: "auf die Finger", src: "NgQDcUPh_0Q" },
          {
            name: "Flöte",
            perspective: "zum Dirigenten",
            src: "SXyOatZSrls",
          },
          {
            name: "Englisch-Horn",
            perspective: "auf den Spieler",
            src: "M-A3VaulASw",
          },
        ],
      },
      {
        name: "III. Menuet",
        videos: [
          {
            name: "360 Grad",
            src: "N30_imuz4TM",
          },
          {
            name: "Kontrabass",
            perspective: "auf den Spieler",
            src: "zL9SoW2kJDc",
          },
          {
            name: "Oboe",
            perspective: "zum Dirigenten",
            src: "GZEgWL8O888",
          },
          {
            name: "Harfe",
            perspective: "auf die Finger oder die Füße",
            src: "e-SMqQi-pCw",
          },
          {
            name: "Klarinette",
            perspective: "auf den Spieler",
            src: "ohSHk9aLsn0",
          },
        ],
      },
      {
        name: "IV. Rigaudon",
        videos: [
          {
            name: "360 Grad",
            src: "b1eaB_RRR3s",
          },
          {
            name: "Horn",
            src: "Jj7_cg8u0FA",
          },
          {
            name: "Fagott",
            src: "QCs7Y7pi9Q8",
          },
          {
            name: "Trompete",
            src: "qDO_2V0ty_Y",
          },
          {
            name: "Oboe",
            src: "g2VwZGb-cRU",
          },
        ],
      },
    ],
  },
  {
    artist: "Sergei Rachmaninow",
    name: "Rhapsodie über ein Thema von Paganini, Op. 43",
    parts: [
      {
        name: "Variationen I bis X",
        videos: [
          {
            name: "360 Grad",
            src: "kCkzZd-S7GA",
          },
          {
            name: "Klavier",
            perspective: "auf die Tasten",
            src: "GUo3oOS0A1M",
          },
          {
            name: "Erste Violinen",
            perspective: "auf die Gruppe + Dirigenten",
            src: "F0NPPmgptEs",
          },
          {
            name: "Zweite Violinen",
            perspective: "auf die Gruppe",
            src: "oqkD1BwjP1M",
          },
          {
            name: "Horn",
            perspective: "zum Dirigenten",
            src: "-5HlzJQ9FqU",
          },
        ],
      },
      {
        name: "Variationen XI bis XVIII",
        videos: [
          {
            name: "360 Grad",
            src: "NGcpyW2sBMQ",
          },
          {
            name: "Klavier",
            perspective: "auf die Tasten",
            src: "tcC8xLUlWHo",
          },
          { name: "Harfe", perspective: "auf die Finger", src: "NYTn0VT0yns" },
          {
            name: "Kontrabass",
            perspective: "zum Dirigenten",
            src: "kgI2n1XRLR4",
          },
          {
            name: "Cello",
            perspective: "auf die Finger",
            src: "OZs-oD7hwDk",
          },
        ],
      },
      {
        name: "Variationen XIX bis XXIV",
        videos: [
          {
            name: "360 Grad",
            src: "WCp5R_bSkoM",
          },
          {
            name: "Klavier",
            perspective: "auf die Tasten",
            src: "_MEYEyE4LvI",
          },
          {
            name: "Snare Drum",
            perspective: "auf das Fell",
            src: "F-BDEnG0nb0",
          },
          {
            name: "Posaune",
            perspective: "auf den Spieler",
            src: "_Wx81jjphII",
          },
          {
            name: "Bratsche",
            perspective: "zum Dirigenten",
            src: "SzRS7rEMZaU",
          },
          { name: "Dirigent", perspective: "zum Dirigenten", src: null },
        ],
      },
    ],
  },
]

export const filteredPieces = pieces.map((piece) => {
  return {
    ...piece,
    parts: piece.parts.map((part) => {
      return { ...part, videos: part.videos.filter((vid) => vid.src !== null) }
    }),
  }
})
