<script>
  export let videos
  import { createEventDispatcher } from "svelte"
  const dispatch = createEventDispatcher()

  export let currentVideo

  let handleClick = (index) => {
    dispatch("select", index)
  }
</script>

<div class="videos reel">
  {#each videos as video, i}
    {#if video.src}
      <div class="video {i == currentVideo ? 'is-active' : ''}">
        <div
          on:click={() => {
            handleClick(i)
          }}
          class="video__name"
        >
          {video.name}
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">
  .videos {
    background-color: var(--warmGray-900);
    padding: 0 1.6rem;
    position: relative;
    z-index: 1;
    align-items: center;
    flex-shrink: 0;
  }

  .video {
    font-size: calc(var(--size-400) * 0.9);
    cursor: pointer;
    padding: 1.6rem 0;
    position: relative;
    transition: all 150ms ease-in-out;
    margin-right: 1.2rem;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      height: 0.4rem;
    }
    &:before {
      --triangle-size: 0.8rem;
      content: "";
      position: absolute;
      bottom: calc(var(--triangle-size) * -1);
      background-color: transparent;
      height: var(--triangle-size);
      width: 0;
      height: 0;
      left: 50%;
      margin-left: calc(var(--triangle-size) * -1);
      border-left: var(--triangle-size) solid transparent;
      border-right: var(--triangle-size) solid transparent;

      border-top: var(--triangle-size) solid transparent;
    }

    &:hover {
      color: var(--color-interactive-light);
    }

    &.is-active {
      color: var(--color-interactive);
      &:after {
        background-color: var(--color-interactive);
      }
      &:before {
        border-top-color: var(--color-interactive);
      }
    }
  }
</style>
